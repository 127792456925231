<template>
  <section class="dtc-main-section mt-4" id="">
    <h1 style="font-size: 23px; display: grid; place-items: start;font-family:DFKai-sb;">
      附件5
    </h1>
    <h1 style="font-size: 23px; display: grid; place-items: center;font-family:DFKai-sb;">
      衛生福利部補助計畫收支明細表
    </h1>
    <h3>補助年度:{{ bYear }}</h3>
    <h3>
      計畫名稱: {{ bYear }}學年度第{{ term}}學期「原住民族及離島地區醫事人員養成計畫」
    </h3>
    <table class="table b-table table-bordered">
      <thead>
        <tr>
          <th rowspan="2" class="align-middle">培育<br>學校</th>
          <th rowspan="2" class="align-middle">人數</th>
          <th colspan="3">註冊費</th>
          <th colspan="6">生活費</th>
          <th colspan="2">旅宿費</th>
          <th rowspan="2" class="align-middle">設備費</th>
          <th rowspan="2" class="align-middle">小計</th>
        </tr>
        <tr>
          <th>學雜費</th>
          <th>學生平安保險費</th>
          <th>其他相關費用</th>
          <th>膳食費</th>
          <th>零用津貼</th>
          <th>課業費</th>
          <th>書籍費</th>
          <th>制服費</th>
          <th>應屆畢業生<br>旅行參加費</th>
          <th>返鄉旅費</th>
          <th>住宿費</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, i) in pageRows">
          <tr>
            <td rowspan="2">{{item.SchoolName}}</td>
            <td class="text-center">{{item.budgetingSum.NumberOfPeople}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_1 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_2 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_3 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_4 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_5 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_6 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_7 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_8 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_9 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_10 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_11 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_12 )}}</td>
            <td>{{$formatPrice(item.budgetingSum.Subtotal)}}</td>
          </tr>
          <tr>
            <td style="width:60px;">小計</td>
            <td colspan="3" class="text-right">
              {{$formatPrice(item.budgetingSum.Fee_1+item.budgetingSum.Fee_2+item.budgetingSum.Fee_3)}}</td>
            <td colspan="6" class="text-right">
              {{$formatPrice(item.budgetingSum.Fee_4+item.budgetingSum.Fee_5+item.budgetingSum.Fee_6+item.budgetingSum.Fee_7+item.budgetingSum.Fee_8+item.budgetingSum.Fee_9)}}
            </td>
            <td colspan="2" class="text-right">{{$formatPrice(item.budgetingSum.Fee_10+item.budgetingSum.Fee_11)}}</td>
            <td>{{$formatPrice(item.budgetingSum.Fee_12)}}</td>
            <td>&nbsp;</td>
          </tr>
        </template>
        <template v-if="pageRows.length==0">
          <tr>
            <td colspan="15" class="text-center">
              Loading...
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot v-if="pageRows.length!=0">
        <tr>
          <th rowspan="2">小計</th>
          <th class="text-center">{{SumNumberOfPeople}}</th>
          <th>{{$formatPrice(SumFee_1 )}}</th>
          <th>{{$formatPrice(SumFee_2 )}}</th>
          <th>{{$formatPrice(SumFee_3 )}}</th>
          <th>{{$formatPrice(SumFee_4 )}}</th>
          <th>{{$formatPrice(SumFee_5 )}}</th>
          <th>{{$formatPrice(SumFee_6 )}}</th>
          <th>{{$formatPrice(SumFee_7 )}}</th>
          <th>{{$formatPrice(SumFee_8 )}}</th>
          <th>{{$formatPrice(SumFee_9 )}}</th>
          <th>{{$formatPrice(SumFee_10 )}}</th>
          <th>{{$formatPrice(SumFee_11 )}}</th>
          <th>{{$formatPrice(SumFee_12 )}}</th>
          <th>{{$formatPrice(SumSubtotal)}}</th>
        </tr>
        <tr>
          <th style="width:60px;">小計</th>
          <th colspan="3" class="text-right">
            {{$formatPrice(SumFee_1+SumFee_2+SumFee_3)}}</th>
          <th colspan="6" class="text-right">
            {{$formatPrice(SumFee_4+SumFee_5+SumFee_6+SumFee_7+SumFee_8+SumFee_9)}}
          </th>
          <th colspan="2" class="text-right">{{$formatPrice(SumFee_10+SumFee_11)}}</th>
          <th>{{$formatPrice(SumFee_12)}}</th>
          <th>&nbsp;</th>
        </tr>
        <tr>
          <td colspan="13" class="text-center">經常門總計(元)</td>
          <td colspan="2" class="text-right">{{$formatPrice(SumSubtotal-SumFee_12 )}}</td>
        </tr>
        <tr>
          <td colspan="13" class="text-center">資本門總計(元)</td>
          <td colspan="2" class="text-right">{{$formatPrice(SumFee_12 )}}</td>
        </tr>
        <tr>
          <td colspan="13" class="text-center">合計(元)</td>
          <td colspan="2" class="text-right">{{$formatPrice(SumSubtotal)}}</td>
        </tr>
      </tfoot>
    </table>
  </section>
</template>

<script>
import html2canvas from "html2canvas";
import queryString from "query-string";
const rowObj = {};
let pageRows = new Array().fill().map((s, t) => {
  return {
    SchoolName: t.SchoolName,
    NumberOfPeople: 0,
    Fee_1: 0,
    Fee_2: 0,
    Fee_3: 0,
    Fee_4: 0,
    Fee_5: 0,
    Fee_6: 0,
    Fee_7: 0,
    Fee_8: 0,
    Fee_9: 0,
    Fee_10: 0,
    Fee_11: 0,
    Fee_12: 0,
    Subtotal: 0
  };
});

export default {
  name: "totalSpend",
  data() {
    return {
      term: 1,
      bYear: "108",
      category: null,
      dtcGridSize: 1,
      schoolName: "",
      pageRows,
      learnFooter: 0,
      lifeFooter: 0,
      travelFooter: 0,
      deviceFooter: 0,
      sumFooter: 0,
      debug: window.dtcDebug,
      supplement: "",

      SumNumberOfPeople: 0,
      SumFee_1: 0,
      SumFee_2: 0,
      SumFee_3: 0,
      SumFee_4: 0,
      SumFee_5: 0,
      SumFee_6: 0,
      SumFee_7: 0,
      SumFee_8: 0,
      SumFee_9: 0,
      SumFee_10: 0,
      SumFee_11: 0,
      SumFee_12: 0,
      SumSubtotal: 0,
    };
  },

  methods: {
    normalizeData(map) {
      map.forEach((s, i) => {
        const item = s.budgetingSum;
        this.SumNumberOfPeople += item.NumberOfPeople;
        this.SumFee_1 += item.Fee_1;
        this.SumFee_2 += item.Fee_2;
        this.SumFee_3 += item.Fee_3;
        this.SumFee_4 += item.Fee_4;
        this.SumFee_5 += item.Fee_5;
        this.SumFee_6 += item.Fee_6;
        this.SumFee_7 += item.Fee_7;
        this.SumFee_8 += item.Fee_8;
        this.SumFee_9 += item.Fee_9;
        this.SumFee_10 += item.Fee_10;
        this.SumFee_11 += item.Fee_11;
        this.SumFee_12 += item.Fee_12;
        this.SumSubtotal += item.Subtotal;
      });
    },
    async getData() {
      //this.learnSpendFooter = this.insureFooter = this.othersFooter = 0;
      //this.travelFooter = this.clothFooter = this.bookFooter = this.classFooter = this.allowFooter = this.eatFooter = 0;
      //this.backHomeFooter = this.liveFooter = 0;
      //this.sumFooter = 0;
      //this.resetRows();

      const { byear, term, category } = queryString.parse(
        location.href.split("?")[1]
      );
      this.bYear = +byear;
      this.term = +term;
      const url = `Budgeting/GetListByYear?byear=${byear}&term=${term}&category=${category}`;
      let map = [];
      try {
        map = await window.axios.get(url);

        if (map) {
          console.log('map', map);
          this.pageRows = map;
          //const schoolName = this.schoolName;
          //this.supplement = map.Supplement;
          this.normalizeData(map);

        } else {
          //alert(`error server api ${url} return 200 but the data is: ` + map);
          const str = `error server api ${url} return status 200 but the data is null`;
          this.$root.$emit("dtc-server-error", str);
          //this.pageRows = [];
          // user can enter data into sheet; not need to empty rows
        }
      } catch (e) {
        this.debug = true;
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  mounted() {
    this.getData();
    //for check is same school-prevent see other school
    // const { byear, term, category } = queryString.parse(
    //   location.href.split("?")[1]
    // );
    // const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    // const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    // const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    // const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    // const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    // if (!isSchoolPerson && !isAdminPerson) {
    //   return;
    // }

    // if (isSchoolPerson) {
    //   if (loginHealthCode != schoolcode) {
    //     return;
    //   }
    // }
    // //check Can see and then get data
    // if (term == 1) {
    //  this.$router.push("/doc4-1?" + location.href.split("?")[1]);
    //  return;
    // }


  },

};
</script>
<style lang="scss">
main .custom-select {
  background: none;
}
</style>
<style lang="scss">
.dtc-main-section ::v-deep .dropdown-toggle {
  min-width: 57px !important;
  font-size: 14px;
  background: white;
  color: black;

  &::after {
    all: unset;
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table-bordered {

  thead,
  tbody,
  tfoot {

    th,
    td {
      border-color: #111 !important;
      border-width: 1px;
      height: 2.5em;
    }
  }
}

.text-right-mode {
  text-align: right;
  padding-right: 5px;
}

@media screen {
  .dtc-main-section {
    max-width: 1500px;
    padding-top: 12px;
    margin: 0 auto;
  }
}

@media print {
  @page {
    size: A4 landscape;
  }

  .dtc-print {
    --dtc-border-color: var(--dark);
    max-width: 98vw;
  }
}
</style>